<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>农机类型管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'farmMachinery-add'">
          <el-button type="primary" size="small" @click="addFarmMachinery">新增农机类型</el-button
          >
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
              v-loading="loading"
              :data="data"
              row-key="id"
            >
              <el-table-column prop="name" label="农机类型"></el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column
                fixed="right"
                min-width="40"
                label="操作"
                align="center"
                v-if="authVerify.verify('farmMachinery-edit,farmMachinery-delete')"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-edit-outline"
                    size="mini"
                    @click="editFarmParcel(scope.row)"
                    v-auth="'farmMachinery-edit'"
                  >
                    编辑
                  </el-button>
                  <el-button
                    icon="el-icon-delete"
                    size="mini"
                    @click="deleteFarmMachinery(scope.row)"
                    v-auth="'farmMachinery-delete'"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10,20,30,40]"
                :current-page="listPage"
                :page-size="listSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
                :total="listTotal">
            </el-pagination>
      </el-card>
    <!-- 添加 编辑 -->
    <a-drawer
      :title="addOrEdit == 'add' ? '新增农机类型' : '编辑农机类型'"
      width="400"
      placement="right"
      :closable="false"
      :visible="addFarmMachineryShow"
      @close="addFarmMachineryShow = false"
    >
      <div style="margin-bottom: 3.5rem">
        <a-form-model
          :model="currentData"
          ref="farmMachineryRefForm"
          :rules="formRules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 17 }"
        >
          <a-form-model-item label="农机类型" prop="name">
            <a-input v-model="currentData.name" placeholder="请输入农机类型名称" allowClear/>
          </a-form-model-item>
          <a-form-model-item label="数量" prop="num">
            <a-input v-model="currentData.num" placeholder="请输入农机数量" allowClear type="number"/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addFarmMachinery"
          >取消</a-button
        >
        <a-button type="primary" @click="handleConfirm">确认</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  components:{},
  data() {
    return {
      currentData: {
        name: "",
        num: 1
      },
      formRules: {
        name: [{ required: true, message: "请输入农机类型!", trigger: "change" }],
        num: [{  required: true, message: "请输入数量!", trigger: "change" }],
      },
      addOrEdit: 'add',
      loading: true,
      addFarmMachineryShow: false,
      data: [],
      listPage: 1,
      listSize: 15,
      listTotal: 0,
    };
  },
  watch: {},
  mounted() {},
  created() {
    // 获取列表数据
    this.getFarmMachineryData();
  },
  filters: {},
  computed: {},
  methods: {
      handlePageChange(val) {
          this.spinning = true;
          this.listPage = val;
          this.getFarmMachineryData();
      },
      handleSizeChange(val) {
          this.spinning = true;
          this.listSize = val;
          this.getFarmMachineryData();
      },
    getFarmMachineryData() {
      this.loading = true;
      let data = {
          current: this.listPage,
          size: this.listSize
      }
      this.http.farmMachinery.getFarmMachineryListPage(data).then((res) => {
        if (res.data.status == 200) {
          let list = res.data.data.records;
          this.data = list;
          this.listTotal = res.data.data.total;
          this.listPage = res.data.data.current;
          this.loading = false;
        } else {
          this.$message.error("获取地块信息失败。");
        }
      });
    },
    deleteFarmMachinery(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.farmMachinery.deleteFarmMachinery({ id: key.id }).then((res) => {
            if (res.data.status == 200) {
              this.$message.success("操作成功");
              self.getFarmMachineryData();
            } else {
              this.$message.error("操作失败，请稍后再试");
            }
          });
        },
        onCancel() {},
      });
    },

    handleConfirm(){
      if(this.addOrEdit == 'add'){
        this.toAddFarmMachinery()
      }
      if(this.addOrEdit == 'edit'){
        this.toEditMachinery()
      }
    },
    addFarmMachinery() {
      this.addOrEdit = 'add';
      this.initCurrentData();
        this.addFarmMachineryShow = true
        this.$refs.farmMachineryRefForm.resetFields()
    },
    editFarmParcel(row) {
      this.addOrEdit = 'edit';
        this.initCurrentData();
        let data = {
            name: row.name,
            id: row.id,
            num: row.num
        };
      this.currentData = data;
      this.addFarmMachineryShow = true
        this.$refs.farmMachineryRefForm.resetFields()
    },

    toAddFarmMachinery() {
      let data = this.currentData;
      // 判断空字段
        this.$refs.farmMachineryRefForm.validate(valid => {
            if (valid) {
                // 添加地块方法
                this.http.farmMachinery.createdFarmMachinery(data).then((res) => {
                    console.log(res);
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.getFarmMachineryData();
                        this.addFarmMachineryShow = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        });
    },
    toEditMachinery() {
      let data = this.currentData;
      // 判断空字段
        this.$refs.farmMachineryRefForm.validate(valid => {
            if (valid) {
                // 添加地块方法
                this.http.farmMachinery.updateFarmMachinery(data).then((res) => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.getFarmMachineryData();
                        this.addFarmMachineryShow = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        });
    },
    initCurrentData() {
      this.currentData = {
        name: "",
        num: 1
      };
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
